import { Box, Card, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ALLOWABLE_HOSTS } from './constants';
import Recipe from './Recipe';

const RecipeParser: FC = () => {
  const [recipeUrl, setRecipeUrl] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (recipeUrl) {
      try {
        const url = new URL(recipeUrl);
        if (!ALLOWABLE_HOSTS.includes(url.hostname)) {
          setError('Invalid recipe.');
          return;
        } else {
          setError('');
        }
      } catch (_) {
        setError('Invalid recipe.');
      }
    } else {
      setError('');
    }
  }, [setError, recipeUrl]);

  return (
    <Box
      alignItems={'center'}
      justifyContent={'center'}
      alignContent={'center'}
      padding={'10px'}
    >
      <Typography
        variant='h5'
        component='div'
        align='center'
        sx={{
          flexGrow: 1,
        }}
      >
        Enter a recipe from either Epicurious, Bon Appétit, or NY Times.
      </Typography>
      <Typography
        variant='h6'
        component='div'
        align='center'
        sx={{
          flexGrow: 1,
        }}
      >
        Just copy and paste from browser. Include 'http(s)', etc.
      </Typography>
      <TextField
        id='standard-basic'
        label='Recipe'
        variant='standard'
        onChange={(e) => {
          setRecipeUrl(e.target.value);
        }}
        error={Boolean(error)}
        helperText={error}
        sx={{ width: '100%' }}
        defaultValue={recipeUrl}
      />

      {!error && (
        <Box paddingTop='10px'>
          <Card>
            <Recipe url={recipeUrl} />
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default RecipeParser;
