import { Menu as MenuIcon } from "@mui/icons-material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";

const NavBar = () => {
  const small = useMediaQuery("(max-width:600px)");
  const full = useMediaQuery("(min-width:600px)");

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar variant="dense">
          {small && (
            <>
              <List>
                <ListItemButton>
                  <Button onClick={handleClick}>
                    <MenuIcon color="secondary" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                  <Typography
                    variant="h6"
                    color="inherit"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Tristan J. Hall
                  </Typography>
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton href="/">
                      <ListItemText primary="Home" />
                    </ListItemButton>
                    <ListItemButton href="recipe-parser">
                      <ListItemText primary="Recipe Parser" />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>
            </>
          )}

          {full && (
            <>
              <Typography variant="h6" color="inherit">
                Tristan J. Hall
              </Typography>
              <Button href="/" color="inherit">
                Home
              </Button>
              <Button href="/recipe-parser" color="inherit">
                Recipe Parser
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export { NavBar };
