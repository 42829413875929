import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Home";
import Layout from "./Layout";
import RecipeParser from "./RecipeParser/RecipeParser";

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/recipe-parser",
        element: <RecipeParser />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
