import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import type { FC } from "react";
import { Outlet } from "react-router-dom";
import { NavBar } from "./Header";

const darkBlue = "#102040";
const darkLightBlue = "#0F2F59";
const lightBlue = "#96B3D9";
const lightEndBlue = "#3B668C";
const brown = "#8C8074";

const theme = createTheme({
  palette: {
    primary: {
      main: darkLightBlue,
      light: lightBlue,
      dark: darkBlue,
      contrastText: brown,
    },
    secondary: {
      main: lightBlue,
    },
    background: { default: lightEndBlue },
  },
});

const Layout: FC<{}> = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBar />
      <Outlet />
    </ThemeProvider>
  );
};

export default Layout;
