import { Box, List, ListItem, Typography } from "@mui/material";
import { FC, useMemo } from "react";

interface Stage {
  readonly stage: string;
  readonly items: string[];
}

interface IRecipeContent {
  readonly stages: Stage[];
  readonly title: string;
}

const RecipeContentList: FC<IRecipeContent> = ({ stages, title }) => {
  const parsedItems = useMemo(
    () =>
      stages.map((stage) => {
        const stageList = stage.items.map((item) => {
          return <ListItem key={item}>{item}</ListItem>;
        });

        return (
          <Box key={stage.stage}>
            <Typography variant='h6' component='div'>
              {stage.stage}
            </Typography>
            <List>{stageList}</List>
          </Box>
        );
      }),
    [stages]
  );

  return (
    <>
      <Typography
        variant='h5'
        component='div'
        sx={{ textDecoration: "underline" }}
        display='inline'
      >
        {title}
      </Typography>
      <List>{parsedItems}</List>
    </>
  );
};

export default RecipeContentList;
