import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

const instance = applyCaseMiddleware(
  axios.create({
    baseURL: 'https://api.tristanjhall.com/',
    headers: {},
  })
);

interface IngredientsDirections {
  readonly stage: string;
  readonly items: string[];
}

export interface RecipeResponse {
  readonly url: string;
  readonly title: string;
  readonly cookingTime: string;
  readonly servings: string;
  readonly ingredients: IngredientsDirections[];
  readonly directions: IngredientsDirections[];
  readonly creator: string;
  readonly rating: string;
  readonly imageUrl: string;
}

export const getRecipeParser = (recipe: string) => {
  return instance.get<RecipeResponse>('/recipe-parser', { params: { recipe } });
};
