import { getRecipeParser } from "../api";
import { useQuery } from "@tanstack/react-query";

export const useGetRecipe = (recipe: string) =>
  useQuery({
    queryKey: ["recipe-parser", recipe],
    queryFn: () => getRecipeParser(recipe).then((res) => res.data),
    enabled: !!recipe,
    refetchOnWindowFocus: false,
  });
