import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  CardContent,
  CardHeader,
  CardMedia,
  LinearProgress,
  Link,
} from '@mui/material';
import { FC, useMemo } from 'react';
import RecipeContentList from './RecipeContentList';
import { useGetRecipe } from '../api-hooks';

type RecipeProps = {
  readonly url: string;
};

const Recipe: FC<RecipeProps> = ({ url }) => {
  const { data, isSuccess, isLoading } = useGetRecipe(url);

  const imageUrl = useMemo(() => {
    if (
      data?.imageUrl.startsWith('https://assets.bonappetit.com') ||
      data?.imageUrl.startsWith('https://static01.nyt.com') ||
      data?.imageUrl.startsWith('https://assets.epicurious.com/')
    ) {
      return data.imageUrl;
    }
  }, [data?.imageUrl]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return isSuccess ? (
    <>
      <CardHeader
        title={data.title}
        subheader={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'left',
              flexDirection: 'column',
              justifyContent: 'left',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Link
                href={url}
                underline='hover'
                target='_blank'
                rel='noreferrer'
              >
                <OpenInNewIcon />
                {url}
              </Link>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                {imageUrl && (
                  <CardMedia
                    component='img'
                    image={imageUrl}
                    alt='image'
                    sx={{
                      padding: '0 1em 0 0',
                      objectFit: 'contain',
                      display: 'flex',
                      width: '10%',
                    }}
                  />
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box>Servings: {data.servings || 'not specified'}</Box>
                  <Box>Total Time: {data.cookingTime || 'not specified'}</Box>
                  <Box>Rating: {data.rating}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        }
      />
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <RecipeContentList stages={data.ingredients} title='Ingredients' />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexShrink: 5,
            }}
          >
            <RecipeContentList stages={data.directions} title='Directions' />
          </Box>
        </Box>
      </CardContent>
    </>
  ) : null;
};

export default Recipe;
